import {
    useState,
    useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Navbar,
    Image,
    Form,
    FormGroup,
    InputGroup,
    Button,
} from 'react-bootstrap';
import '../App.css';

import { Formik } from 'formik'
import * as Yup from 'yup'

import {
    CategoryList,
    UploadVideo,
    UploadImage, LoginPost, CreateInfluencerPost, getInfluencerUserList,
} from '../restHelper'

const validationSchema = function () {
    return Yup.object().shape({
        userId: Yup.string().required('userId is required'),
        mediaId: Yup.string().required('Media is required'),
        categories: Yup.array().min(1, 'choose at least 1 category'),
        thumbnailMediaId: Yup.string().required('ThumbnailMedia is required'),
    })
}

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, { abortEarly: false })
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
}

const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors, error) => {
        return {
            ...errors,
            [error.path]: error.errors[FIRST_ERROR],
        }
    }, {})
}

const ChallengeView = () => {
    const dispatch = useDispatch()

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [userList, setUserList] = useState(null);
    const [duration, setDuration] = useState(0);
    const [fileFormKey, setFileFormKey] = useState(0);
    const defaultValue = "please choose your id";

    let categoryList = useSelector(state => state.categoryList);
    let errorMessage = useSelector(state => state.errorMessage);

    function getVideoCover(file, seekTo = 0.0) {
        // console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // console.log('video duration is', videoPlayer.duration)
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve([blob, videoPlayer.duration]);
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        });
    }

    const uploadMedia = async (file, setFieldValue) => {
        const [cover, duration] = await getVideoCover(file, 1.5);
        // print out the result image blob
        setDuration(Math.round(duration));
        const img = document.getElementById('thumbnailImg');
        const imgUrl = URL.createObjectURL(cover)
        img.src = imgUrl

        if (!file) {
            setFieldValue('mediaId', "");
            return;
        }

        const formData = new FormData();
        formData.append("file", file, file.name)
        await UploadVideo(dispatch, formData)
            .then((fileData) => {
                if (!fileData) {
                    alert(errorMessage);
                } else {
                    setFieldValue('mediaId', fileData.mediaId);
                }
            });

        const formData2 = new FormData();
        formData2.append("file", cover, 'thumbnail.jpeg')
        await UploadImage(dispatch, formData2)
            .then((fileData) => {
                if (!fileData) {
                    alert(errorMessage);
                } else {
                    // console.log('thumbnailMediaId', fileData.mediaId)
                    setFieldValue('thumbnailMediaId', fileData.mediaId);
                }
            });
    }

    const selectCategory = (id, categories, setFieldValue) => {
        // console.log(id);
        // console.log(categories);
        if (categories.includes(id)) {
            let index = categories.indexOf(id);
            categories.splice(index, 1);
        } else {
            categories.push(id);
        }

        setFieldValue("categories", categories);
    }

    const setHashtags = (hashtags, setFieldValue) => {
        let hastagsSplit = hashtags.replace(/\s/g, "").split('#');

        setFieldValue("hashtags", hastagsSplit.filter(n => n));
    }

    const onSubmit = (values) => {}

    useEffect(() => {
        CategoryList(dispatch);
    }, [])

    let initialValues = {
        userId: "please choose your id",
        mediaId: "",
        categories: [],
        hashtags: [],
    }

    return (
        <>
            {isLoggedIn ? (
                <Container fluid>
                    <Navbar expand="sm">
                        <Container>
                            <Navbar.Brand href="">
                                <Image src="fullicon.png" className="ZiguLogo" />
                            </Navbar.Brand>
                        </Container>
                    </Navbar>
                        <Formik
                            initialValues={initialValues}
                            validate={validate(validationSchema)}
                            onSubmit={onSubmit}
                        >
                            {
                                ({
                                     values,
                                     errors,
                                     handleSubmit,
                                     isSubmitting,
                                     setFieldValue,
                                     resetForm,
                                 }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                                <FormGroup>
                                                    <Form.Label>Uploader</Form.Label>
                                                    <Row className={"ml-1"}>
                                                        <select style={{ width: 400, paddingLeft: 4 }} onChange={(e) => {
                                                            setFieldValue("userId", e.target.value);
                                                        }}>
                                                            <option selected value={defaultValue}>{defaultValue}</option>
                                                            {userList && userList.map((item) => {
                                                                return <option value={item.userId}>{item.userId}</option>
                                                            })}
                                                        </select>
                                                    </Row>
                                                </FormGroup>
                                            </Col>

                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                                <FormGroup>
                                                    <Form.Label>Media</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="file" id="mediaFile" name="mediaFile" key={fileFormKey} onChange={(e) => { uploadMedia(e.target.files[0], setFieldValue) }} />
                                                        <Form.Text>{errors.mediaId}</Form.Text>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                                <img id='thumbnailImg' width={'100%'} />
                                            </Col>

                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                                <FormGroup>
                                                    <Form.Label>Categories</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        {categoryList && categoryList.categories.map((category => {
                                                            return (
                                                                <Button className="m-1" variant={values.categories.includes(category.id) ? "primary" : "secondary"} onClick={() => { selectCategory(category.id, values.categories, setFieldValue) }}>{category.name}</Button>
                                                            )
                                                        }))}
                                                        <Form.Text>{errors.categories}</Form.Text>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                                <FormGroup>
                                                    <Form.Label>Hashtags</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="text"
                                                                      name="hashtags"
                                                                      id="hashtags"
                                                                      placeholder="hashtags"
                                                                      onChange={(e) => { setHashtags(e.target.value, setFieldValue) }} />
                                                        <Form.Text>{errors.hashtags}</Form.Text>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="d-flex justify-content-center align-items-center">
                                                <div className="text-center">
                                                    <Button type="submit" variant="primary" size="lg" disabled={values.categories.length < 1 || !values.mediaId || isSubmitting || values.userId === defaultValue} onClick={() => {
                                                        CreateInfluencerPost(dispatch, values, token, duration).then((res) => {
                                                            resetForm({
                                                                values: {
                                                                    userId: "",
                                                                    mediaId: "",
                                                                    categories: [],
                                                                    hashtags: [],
                                                                }
                                                            });
                                                            setFileFormKey(fileFormKey + 1);
                                                            setFieldValue("userId", values.userId);

                                                            if(res.success) {
                                                                alert('successfully uploaded!');
                                                            } else {
                                                               alert(res.message);
                                                            }


                                                        });
                                                    }}>Create</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                </Container>
            ) : (
                <Container fluid>
                    <Navbar expand="sm">
                        <Container>
                            <Navbar.Brand href="">
                                <Image src="fullicon.png" className="ZiguLogo" />
                            </Navbar.Brand>
                        </Container>
                    </Navbar>
                        <Row>
                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                <FormGroup>
                                    <Form.Label>Id</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text"
                                                      name="id"
                                                      required
                                                      onChange={(e) => {
                                                          setId(e.target.value);
                                                      }}
                                                      value={id} />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                                <FormGroup>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="password"
                                                      name="password"
                                                      required
                                                      onChange={(e) => {
                                                          setPassword(e.target.value);
                                                      }}
                                                      value={password} />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <Button type="submit" variant="primary" size="lg" disabled={!id || !password} onClick={() => {
                                        LoginPost(dispatch, id, password).then((res) => {
                                            if(res.success) {
                                                const token = res.data.token;
                                                setToken(token);
                                                getInfluencerUserList(dispatch, token).then((res) => {
                                                    if(res.success) {
                                                        const userList = res.data;
                                                        setUserList(userList);
                                                        setIsLoggedIn(true);
                                                    } else {
                                                        alert(res.message);
                                                    }
                                                });
                                            } else {
                                                alert('please recheck your id or password');
                                            }
                                        });
                                    }}>Login</Button>
                                </div>
                            </Col>
                        </Row>
                </Container>
            )}
        </>
    );
}

export default ChallengeView;