import {
    useState,
    useEffect
} from 'react'
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Image,
    Button, NavDropdown
} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import '../App.css';
const axios = require('axios')

function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const MainView = () => {
    const [play, setPlay] = useState(false);
    const [post, setPost] = useState(null);

    useEffect(() => {

    }, [])

    return (
        <>
        <Navbar expand="sm" className="sticky-top d-none d-lg-block" style={{ width: '100%', backgroundColor: 'white'}}>
            <Container>
            <Navbar.Brand href="#home">
                <Image src="fullicon.png" className="ZiguLogo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="ml-auto">
                    <Nav.Link className="MenuPill" href="#home">Why ZIKTALK?</Nav.Link>
                    <Nav.Link className="MenuPill" href="#features">Features</Nav.Link>
                    <NavDropdown id='Whitepaper' title={
                        <span className="MenuPill">Whitepaper</span>
                    }>
                        <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_v2.5.pdf">English</NavDropdown.Item>
                        <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_2.5_kr.pdf">Korean</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="MenuPill" href="/Frequently_Asked_Questions.pdf">Frequently Asked Questions</Nav.Link>
                    <Nav.Link className="MenuPill" href="/Privacy_Terms.pdf">Privacy Terms</Nav.Link>
                    <Nav.Link className="MenuPill" href="/Terms_of_Service.pdf">Terms of Service</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
            <Container className="d-none d-lg-block" fluid style={{ backgroundImage: "url(/ellipse-big.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '100% 10%' }}>
                <Row className="PhoneBackground" style={{ backgroundImage: "url(/header-phones.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '80% -50%' }}>
                    <Container>
                        <Row className="HeaderBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <div className="WelcomeText mb-4">Welcome to ZIKTALK!</div>
                                <div className="HeadingText mb-5">
                                    Create.<br />
                                    Enjoy.<br />
                                    Earn.
                            </div>
                                <Button className="GetStartedButton mb-4" href={(iOS() ? "https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345" : "https://play.google.com/store/apps/details?id=com.fronty.ziktalk2")}>Get Started</Button>
                                <div className="AvailableOnText  d-flex align-items-center">
                                    * Available on
                                    <Image className="PlatformLogo" src="android-logo.png" />
                                    <Image className="PlatformLogo" src="ios-logo.png" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Container id="home">
                    <Row className="FullBorder Small">
                        <Container>
                            <Row className="HeaderBox">
                                <Col className="d-none">
                                    <div><span>F</span><span>Y</span></div>
                                    <div><span>T</span><span>I</span></div>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <div className="text-center">
                                        <div className="EarnHeadingText mb-3">Earn rewards for every video you create!</div>
                                        <div className="EarnContentText">We believe that every creator deserves to be rewarded.<br />Start ZIKTALK today and begin earning.</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row>

                    <Row>
                        <Col className="FullBorder mr-5 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <Image src="video-play.png" />
                                <div className="EarnHeadingText mt-4">Creator</div>
                                <div className="EarnContentText pr-5 pl-5 pt-4">Share short-videos with the world. The more engagement you get, the more you earn.</div>
                            </div>
                        </Col>

                        <Col className="FullBorder ml-5 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <Image src="user-tag.png" />
                                <div className="EarnHeadingText mt-4">User</div>
                                <div className="EarnContentText pr-5 pl-5 pt-4">Some of us just like to watch other people's videos. ZIKTALK rewards users who just watch!</div>
                            </div>
                        </Col>
                    </Row>


                    <Row className="OurMissionBox">
                        <Col className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <div className="EarnContentText mb-3">Our mission</div>
                                <div className="OurMissionContent mb-3">To connect the world and to reward<br /> those who play a part in doing so.</div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row className="TopBorder" id="features">
                    <Container>
                        <Row className="RewardBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div>
                                    <div className="EarnHeadingText mb-4">Reward system</div>
                                    <div className="EarnContentText mb-4">For every like, comment, and follow you get, you receive ZIK tokens. These tokens can be converted to cash.</div>
                                    <a href="https://medium.com/ziktalk/ziktalk-has-updated-to-version-2-0-4cd827d4f705" className="LearnMoreText">Learn more &gt;</a>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ backgroundImage: "url(/wallet-screen.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '100% -100%' }}>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="GreyBackground">
                    <Container>
                        <Row className="FeaturesBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Short-video</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Vertical videos for sharing everything from language to hobbies to culture.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Video-only</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">ZIKTALK is revolutionary in that there is no text - comments are also in video form!</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Creator Rewards</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Be rewarded for the views, likes, follows, and comments you receive.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Wallet</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Hold, send, and receive your ZIK rewards in ZIKTALK's wallet.</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="BlackBackground" id="download">
                    <Col sm={{ span: 12 }} className="d-flex align-items-end">
                        <div className="DownloadText text-center">
                            Download ZIKTALK now!
                        </div>
                    </Col>

                    <Col sm={{ span: 12 }} className="d-flex align-items-start mt-5">
                        <div className="text-center w-100">
                            <a href="https://play.google.com/store/apps/details?id=com.fronty.ziktalk2">
                                <Image src="android-store.png" />
                            </a>
                            <a href="https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345" className="ml-5">
                                <Image src="ios-store.png" />
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row className="FooterBox">
                    <Container>
                        <Row className="mt-5">
                            <Col sm={{ span: 12 }} md={{ span: 6 }}>
                                <div style={{ marginBottom: '162px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div className="ZiktalkText">ZIKTALK </div>
                                        <div className="BottomEmailText">business@ziktalk.com</div>
                                    </div>
                                    <div className="BottomCopyText">Copyright&copy;2022 Ziktalk. All rights reserved.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} className="d-flex justify-content-end">
                                <ul className="social-list list-inline">
                                    <li>
                                        <a href="https://www.facebook.com/ziktalk">
                                            <img src="facebook.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/ziktalk.official/">
                                            <img src="instagram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/ziktalkofficial">
                                            <img src="twitter.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/ziktoken">
                                            <img src="icon-telegram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://medium.com/ziktalk">
                                            <img src="icon-medium.svg" className="social-icon" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Navbar expand="sm" className="sticky-top d-lg-none d-none d-sm-block d-md-block" style={{backgroundColor: 'white'}}>
                <Container>
                    <Navbar.Brand href="#home">
                        <Image src="fullicon.png" className="ZiguLogo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="ml-auto">
                            <Nav.Link className="MenuPill" href="#home-md">Why ZIKTALK?</Nav.Link>
                            <Nav.Link className="MenuPill" href="#features-md">Features</Nav.Link>
                            <NavDropdown id='Whitepaper' title={
                                <span className="MenuPill">Whitepaper</span>
                            }>
                                <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_v2.5.pdf">English</NavDropdown.Item>
                                <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_2.5_kr.pdf">Korean</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="d-lg-none d-none d-sm-block d-md-block" fluid style={{ backgroundImage: "url(/ellipse-medium.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '100% 10%' }}>
                <Row className="PhoneBackground" style={{ backgroundImage: "url(/header-phones-medium.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '90% 50%' }}>
                    <Container>
                        <Row className="HeaderBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <div className="WelcomeText mb-4">Welcome to ZIKTALK!</div>
                                <div className="HeadingText mb-5">
                                    Create.<br />
                                    Enjoy.<br />
                                    Earn.
                            </div>
                                <Button className="GetStartedButton mb-4" href={(iOS() ? "https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345" : "https://play.google.com/store/apps/details?id=com.fronty.ziktalk2")}>Get Started</Button>
                                <div className="AvailableOnText  d-flex align-items-center">
                                    * Available on
                                <Image className="PlatformLogo" src="android-logo.png" />
                                    <Image className="PlatformLogo" src="ios-logo.png" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Container>
                    <Row className="FullBorder Small" id="home-md">
                        <Container>
                            <Row className="HeaderBox">
                                <Col className="d-none">
                                    <div><span>F</span><span>Y</span></div>
                                    <div><span>T</span><span>I</span></div>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <div className="text-center">
                                        <div className="EarnHeadingText mb-3">Earn rewards for every video you create!</div>
                                        <div className="EarnContentText">We believe that every creator deserves to be rewarded.<br />Start ZIKTALK today and begin earning.</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row>

                    <Row>
                        <Col className="FullBorder mr-5 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <Image src="video-play.png" />
                                <div className="EarnHeadingText mt-4">Creator</div>
                                <div className="EarnContentText pr-5 pl-5 pt-4">Share short-videos with the world. The more engagement you get, the more you earn.</div>
                            </div>
                        </Col>

                        <Col className="FullBorder ml-5 d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <Image src="user-tag.png" />
                                <div className="EarnHeadingText mt-4">User</div>
                                <div className="EarnContentText pr-5 pl-5 pt-4">Some of us just like to watch other people's videos. ZIKTALK rewards users who just watch!</div>
                            </div>
                        </Col>
                    </Row>


                    <Row className="OurMissionBox">
                        <Col className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <div className="EarnContentText mb-3">Our mission</div>
                                <div className="OurMissionContent mb-3">To connect the world and to reward<br /> those who play a part in doing so.</div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row className="TopBorder" id="features-md">
                    <Container>
                        <Row className="RewardBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div>
                                    <div className="EarnHeadingText mb-4">Reward system</div>
                                    <div className="EarnContentText mb-4">For every like, comment, and follow you get, you receive ZIK tokens. These tokens can be converted to cash.</div>
                                    <a href="https://medium.com/ziktalk/ziktalk-has-updated-to-version-2-0-4cd827d4f705" className="LearnMoreText">Learn more &gt;</a>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} style={{ backgroundImage: "url(/wallet-screen.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '100% -100%' }}>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="GreyBackground">
                    <Container>
                        <Row className="FeaturesBox">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Short-video</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Vertical videos for sharing everything from language to hobbies to culture.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Video-only</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">ZIKTALK is revolutionary in that there is no text - comments are also in video form!</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Creator Rewards</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Be rewarded for the views, likes, follows, and comments you receive.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="PurpleHeaderText">Wallet</div>
                                    <div className="EarnContentText pr-5 pl-5 pt-4">Hold, send, and receive your ZIK rewards in ZIKTALK's wallet.</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="BlackBackground" id="pricing-md">
                    <Col sm={{ span: 12 }} className="d-flex align-items-end">
                        <div className="DownloadText text-center">
                            Download ZIKTALK now!
                        </div>
                    </Col>

                    <Col sm={{ span: 12 }} className="d-flex align-items-start mt-5">
                        <div className="text-center w-100">
                            <a href="https://play.google.com/store/apps/details?id=com.fronty.ziktalk2">
                                <Image src="android-store.png" />
                            </a>
                            <a href="https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345" className="ml-5">
                                <Image src="ios-store.png" />
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row className="FooterBox">
                    <Container>
                        <Row className="mt-5">
                            <Col sm={{ span: 12 }} md={{ span: 12 }} className="d-flex justify-content-center">
                                <ul className="social-list list-inline">
                                    <li>
                                        <a href="https://www.facebook.com/ziktalk">
                                            <img src="facebook.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/ziktalk.official/">
                                            <img src="instagram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/ziktalkofficial">
                                            <img src="twitter.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/ziktoken">
                                            <img src="icon-telegram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://medium.com/ziktalk">
                                            <img src="icon-medium.svg" className="social-icon" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={{ span: 12 }} md={{ span: 12 }} className="d-flex justify-content-center">
                                <div style={{ marginTop: '47px', marginBottom: '162px'}}>
                                    <div style={{display: 'flex'}} className="justify-content-center">
                                        <div className="ZiktalkText">ZIKTALK </div>
                                        <div className="BottomEmailText">business@ziktalk.com</div>
                                    </div>
                                    <div className="BottomCopyText justify-content-center">Copyright&copy;2022 Ziktalk. All rights reserved.</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Navbar expand="sm d-sm-none" className="sticky-top" style={{backgroundColor: 'white'}}>
                    <Container>
                        <Navbar.Brand href="#home-sm">
                            <Image src="fullicon.png" className="ZiguLogo" />
                        </Navbar.Brand>
                        <NavDropdown id='Whitepaper' title={
                            <span className="MenuPill">Whitepaper</span>
                        }>
                            <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_v2.5.pdf">English</NavDropdown.Item>
                            <NavDropdown.Item href="/Ziktalk_Whitepaper_Web_3.0_Social_Platform_2.5_kr.pdf">Korean</NavDropdown.Item>
                        </NavDropdown>
                    </Container>
                </Navbar>
            <Container className="d-sm-none" fluid style={{ backgroundImage: "url(/ellipse-small.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '100% 7%' }}>
                

                <Row className="PhoneBackground d-sm-none" style={{ backgroundImage: "url(/header-phones.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '50% 100%', backgroundSize: '250px 250px' }}>
                    <Container>
                        <Row className="HeaderBoxMobile mt-5">
                            <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <div className="WelcomeTextMobile mb-4">Welcome to ZIKTALK!</div>
                                <div className="HeadingTextMobile mb-4">
                                    Create.<br />
                                    Enjoy.<br />
                                    Earn.
                            </div>
                                <Button className="GetStartedButton mb-4" href={(iOS() ? "https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345" : "https://play.google.com/store/apps/details?id=com.fronty.ziktalk2")}>Get Started</Button>
                                <div className="AvailableOnText  d-flex align-items-center">
                                    * Available on
                                <Image className="PlatformLogo" src="android-logo.png" />
                                    <Image className="PlatformLogo" src="ios-logo.png" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>


                <Row className="FullBorderMobile Small mt-5" id="home-sm">
                    <Container>
                        <Row className="HeaderBox">
                            <Col className="d-none">
                                <div><span>F</span><span>Y</span></div>
                                <div><span>T</span><span>I</span></div>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="">
                                    <div className="EarnHeadingTextMobile mb-3">Earn rewards for every video you create!</div>
                                    <div className="EarnContentTextMobile">We believe that every creator deserves to be rewarded.<br />Start ZIKTALK today and begin earning.</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row>
                    <Col className="FullBorderCornerMobile ml-4 mr-4 d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <Image src="video-play.png" />
                            <div className="EarnHeadingTextMobile mt-4">Creator</div>
                            <div className="EarnContentTextMobile pr-5 pl-5 pt-4">Share short-videos with the world. The more engagement you get, the more you earn.</div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="FullBorderCornerMobile ml-4 mr-4 d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <Image src="user-tag.png" />
                            <div className="EarnHeadingTextMobile mt-4">User</div>
                            <div className="EarnContentTextMobile pr-5 pl-5 pt-4">Some of us just like to watch other people's videos. ZIKTALK rewards users who just watch!</div>
                        </div>
                    </Col>
                </Row>

                <Row className="OurMissionBox">
                    <Col className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <div className="EarnContentText mb-3">Our mission</div>
                            <div className="OurMissionContent mb-3">To connect the world and to reward<br /> those who play a part in doing so.</div>
                        </div>
                    </Col>
                </Row>

                <Row className="TopBorder" id="features-sm">
                    <Container>
                        <Row className="RewardBoxMobile">
                            <Col sm={{ span: 12 }} className="d-flex mt-5" style={{ backgroundImage: "url(/wallet-screen-small.png)", backgroundRepeat: 'no-repeat', backgroundPosition: '50% 180%' }}>
                                <div>
                                    <div className="EarnHeadingText mb-4">Reward system</div>
                                    <div className="EarnContentText mb-4">For every like, comment, and follow you get, you receive ZIK tokens. These tokens can be converted to cash.</div>
                                    <a href="https://medium.com/ziktalk/ziktalk-has-updated-to-version-2-0-4cd827d4f705" className="LearnMoreText">Learn more &gt;</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="GreyBackgroundMobile">
                    <Container>
                        <Row className="FeaturesBoxMobile">
                            <Col sm={{ span: 12 }} className="d-flex align-items-center mb-3">
                                <div className="text-center">
                                    <div className="PurpleHeaderTextMobile">Short-video</div>
                                    <div className="EarnContentTextMobile pr-5 pl-5 pt-4">Vertical videos for sharing everything from language to hobbies to culture.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} className="d-flex align-items-center mb-3 mt-5">
                                <div className="text-center">
                                    <div className="PurpleHeaderTextMobile">Video-only</div>
                                    <div className="EarnContentTextMobile pr-5 pl-5 pt-4">ZIKTALK is revolutionary in that there is no text - comments are also in video form!</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} className="d-flex align-items-center mb-3 mt-5">
                                <div className="text-center">
                                    <div className="PurpleHeaderTextMobile">Creator Rewards</div>
                                    <div className="EarnContentTextMobile pr-5 pl-5 pt-4">Be rewarded for the views, likes, follows, and comments you receive.</div>
                                </div>
                            </Col>

                            <Col sm={{ span: 12 }} className="d-flex align-items-center mt-5">
                                <div className="text-center">
                                    <div className="PurpleHeaderTextMobile">Wallet</div>
                                    <div className="EarnContentTextMobile pr-5 pl-5 pt-4">Hold, send, and receive your ZIK rewards in ZIKTALK's wallet.</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="BlackBackground">
                    <Col sm={{ span: 12 }} className="d-flex align-items-end">
                        <div className="DownloadText text-center">
                            Download ZIKTALK now!
                        </div>
                    </Col>

                    <Col sm={{ span: 12 }} className="d-flex align-items-start mt-5">
                        <div className="text-center w-100">
                            <a href="https://play.google.com/store/apps/details?id=com.fronty.ziktalk2">
                                <Image src="android-store.png" />
                            </a>
                        </div>
                    </Col>
                    <Col sm={{ span: 12 }} className="d-flex align-items-start">
                        <div className="text-center w-100">
                            <a href="https://apps.apple.com/us/app/ziktalk-earn-social/id1166810345">
                                <Image src="ios-store.png" />
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row className="FooterBox">
                    <Container>
                        <Row className="mt-5">
                            <Col sm={{ span: 12 }} className="d-flex justify-content-center">
                                <ul className="social-list list-inline Mobile">
                                    <li>
                                        <a href="https://www.facebook.com/ziktalk">
                                            <img src="facebook.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/ziktalk.official/">
                                            <img src="instagram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/ziktalkofficial">
                                            <img src="twitter.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/ziktoken">
                                            <img src="icon-telegram.svg" className="social-icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://medium.com/ziktalk">
                                            <img src="icon-medium.svg" className="social-icon" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>

                            <Col sm={{ span: 12 }} className="justify-content-center">
                                <div style={{ marginTop: '47px'}}>
                                    <div className="ZiktalkText d-flex justify-content-center">ZIKTALK </div>
                                    <div className="BottomEmailText-Mobile d-flex justify-content-center">business@ziktalk.com</div>
                                </div>

                                <div style={{ marginTop: '47px', marginBottom: '162px'}}>
                                    <div className="BottomCopyText d-flex justify-content-center">Copyright&copy;2022 Ziktalk.</div>
                                    <div className="BottomCopyText d-flex justify-content-center">All rights reserved.</div>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );
}

export default MainView;
