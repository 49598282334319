import {
    useState,
    useEffect
} from 'react'
import {
    Container,
    Row,
    Col,
    Image,
    Button
} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useParams, useSearchParams } from 'react-router-dom';
import '../App.css';
const axios = require('axios')
const apiKey = "AIzaSyCq3ylew8g4QngEfGnKtjxAQbZ5OGqz3uc"

const PostView = () => {
    const [play, setPlay] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [post, setPost] = useState(null);
    const [dynamicLink, setDynamicLink] = useState(null)

    const { id } = useParams();
    const [ searchParams ] = useSearchParams()
    const userId = searchParams.get('userId')
    const baseUrl = "https://api.zigu.world/api/v1.0"

    const getVideoLink = (retries) => {
        if (retries <= 0) {
            return
        }
        return axios.get(baseUrl + `/Post/view/${encodeURIComponent(id)}`)
            .then(async (res) => {
                let data = res.data
                if (data.success) {
                    const postData = data.data
                    if (postData.isVideoProcessing) {
                        setTimeout(() => getVideoLink(retries - 1), 5000)
                    } else {
                        setPost(data.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    let response = error.response.data
                    console.error(response.message)
                } else {
                    console.error("Server error. Retry later.")
                }
            })
    }
    const getPostData = () => {
        setIsLoading(true)
        return axios.get(baseUrl + `/Post/view/${encodeURIComponent(id)}`)
            .then(async (res) => {
                let data = res.data
                setIsLoading(false)
                if (data.success) {
                    const postData = data.data
                    setPost(postData);
                    if (userId) {
                        const result = await getDynamicLink(data.data.author.id, userId)
                        setDynamicLink(result.shortLink)
                    }
                    if (postData.isVideoProcessing) {
                        setTimeout(() => getVideoLink(3), 3000)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                if (error.response) {
                    let response = error.response.data
                    console.error(response.message)
                } else {
                    console.error("Server error. Retry later.")
                }
            })
    }

    const getDynamicLink = async (authorId, userId) => {
        const link = `https://zigu.world/referral?authorId=${encodeURIComponent(authorId)}&userId=${encodeURIComponent(userId)}`
        try {
            const resp = await axios({
                method: 'POST',
                url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
                headers: {
                    'Content-Type' : 'application/json',
                },
                data: {
                    dynamicLinkInfo: {
                        domainUriPrefix: "https://ziktalk.page.link",
                        link: link,
                        androidInfo: {
                            androidPackageName: "com.fronty.ziktalk2",
                            androidMinPackageVersionCode: "810"
                        },
                        iosInfo: {
                            iosAppStoreId: "1166810345",
                            iosBundleId: "com.fronty.ziktalk2"
                        }
                    }
                },
                suffix: {
                    option: 'SHORT',
                },
            })
            return resp.data
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        getPostData();
    }, [])

    return (
        <div>
            <Container className="fluid">
                <Row>
                    <Col sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 5, offset: 4 }} className="player-wrapper">
                        {post && (
                            <>
                                {post.author.referralLink && !dynamicLink && (
                                    <div className="DownloadAppBox">
                                        <div className="Content">
                                            <Image src="/favicon.png" className="ZiguSmallLogo" />
                                            Open Ziktalk to explore<br/> more video comments!
                                            <Button className="DownloadButton" href={post.author.referralLink}>Download</Button>
                                        </div>
                                    </div>
                                )}
                                {dynamicLink && (
                                    <div className="DownloadAppBox">
                                        <div className="Content">
                                            <Image src="/favicon.png" className="ZiguSmallLogo" />
                                            Open Ziktalk to explore<br/> more video comments!
                                            <Button className="DownloadButton" href={dynamicLink}>Download</Button>
                                        </div>
                                    </div>
                                )}
                                <button className="TransparentButton" onClick={() => { setPlay(!play) }}>
                                    <ReactPlayer width="100%" height="100%" url={post.mediaPath} playing={play} loop={true} className="react-player" />
                                    <div className="PostData">
                                        <img src={post.author.profileMediaPath} className="ProfilePicture" />
                                        <div className="TextBox">
                                            <div className="Username">@{post.author.username}</div>
                                            <div className="HashtagBox">
                                                {post.categories.map((category, index) => {
                                                    return (
                                                        <span key={index} className="Hashtag">#{category.name}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {!play && (
                                        <div className="PlayOverlay">
                                            <img className="PlayIcon" src={process.env.PUBLIC_URL + '/play-icon.svg'} />
                                        </div>
                                    )}
                                </button>
                            </>
                        )}

                        {!post && (
                            <div className="PlayOverlay">
                                {!isLoading && <span className="NotFound">Post Not Found</span>}
                                {isLoading && <span className="NotFound">Loading Post...</span>}
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PostView;