import axios from "axios";

const baseUrl = "https://api.zigu.world/api/v1.0" //PROD
// const baseUrl = "https://test.api.zigu.world/api/v1.0" //DEV
// const baseUrl = "http://localhost:3010/api/v1.0" // local

// const adminBaseUrl = "http://localhost:3020/api/v1.0" //LOCAL NODE.JS
// const adminBaseUrl = "http://test.api.admin.zigu.world:3020/api/v1.0" //DEV
const adminBaseUrl = "https://api.admin.zigu.world/api/v1.0" //PROD

const fetchingUrls = {

}

export const CategoryList = (dispatch) => {
    if (fetchingUrls.categoryList == true) {
        return;
    }

    fetchingUrls.categoryList = true;

    const axios = require('axios')

    dispatch({ type: 'set', restError: null })

    return axios
        .get(baseUrl + "/Hashtag/list")
        .then((res) => {
            fetchingUrls.categoryList = false;

            let data = res.data

            if (data.success) {
                dispatch({ type: 'set', categoryList: data.data })
            } else {
                dispatch({ type: 'set', categoryList: null })
                dispatch({ type: 'set', restError: data.errorMessage })
            }
        })
        .catch((error) => {
            fetchingUrls.categoryList = false;

            let response = error.response.data

            dispatch({ type: 'set', categoryList: null })
            dispatch({ type: 'set', restError: response.errorMessage })
        })
}

export const CreateChallenge = (dispatch, values) => {
    const axios = require('axios')

    dispatch({ type: 'set', restError: null })

    return axios
        .post(
            baseUrl + "/challenge/create",
            { code: values.code, thumbnailMediaId: values.thumbnailMediaId, videoMediaId: values.mediaId, categories: values.categories, hashtags: values.hashtags }
        )
        .then((res) => {
            let data = res.data

            if (data.success) {
                return true;
            } else {
                dispatch({ type: 'set', restError: data.errorMessage })

                return false;
            }
        })
        .catch((error) => {
            let response = error.response.data

            dispatch({ type: 'set', restError: response.errorMessage })

            return false;
        })
}

export const UploadVideo = (dispatch, file) => {
    if (fetchingUrls.uploadVideo == true) {
        return;
    }

    fetchingUrls.uploadVideo = true;

    const axios = require('axios')

    dispatch({ type: 'set', restError: null })

    return axios
        .post(
            baseUrl + "/Utils/uploadVideo",
            file
        )
        .then((res) => {
            fetchingUrls.uploadVideo = false;

            let data = res.data

            if (data.success) {
                return data.data
            } else {
                dispatch({ type: 'set', restError: data.errorMessage })
            }
        })
        .catch((error) => {
            fetchingUrls.uploadVideo = false;

            alert(error);

            let response = error.response.data

            dispatch({ type: 'set', restError: response.errorMessage })
        })
}

export const UploadImage = (dispatch, file) => {
    if (fetchingUrls.uploadImage == true) {
        return;
    }

    fetchingUrls.uploadImage = true;

    const axios = require('axios')

    dispatch({ type: 'set', restError: null })

    return axios
        .post(
            baseUrl + "/Utils/uploadImage",
            file
        )
        .then((res) => {
            fetchingUrls.uploadImage = false;

            let data = res.data

            if (data.success) {
                return data.data
            } else {
                dispatch({ type: 'set', restError: data.errorMessage })
            }
        })
        .catch((error) => {
            fetchingUrls.uploadImage = false;

            alert(error);

            let response = error.response.data

            dispatch({ type: 'set', restError: response.errorMessage })
        })
}

export const LoginPost = async (dispatch, id, password) => {
    return axios
        .post(
            adminBaseUrl + "/auth/login",
            { username: id, password: password }
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            return error.response.data;
        });
}


export const CreateInfluencerPost = (dispatch, values, token, duration) => {
    return axios
        .post(
            adminBaseUrl + "/post/influencerCreate",
            { userId: values.userId, mediaId: values.mediaId,  categories: values.categories, hashtags: values.hashtags,
                thumbnailMediaId: values.thumbnailMediaId, postLength: duration },
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            return error.response.data;
        });
}


export const getInfluencerUserList = (dispatch, token) => {
    return axios
        .get(
            adminBaseUrl + "/post/influencerUserIds",
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            return error.response.data;
        });
}

