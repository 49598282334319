import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import './App.css';

import MainView from './views/MainView'
import PostView from './views/PostView'
import ChallengeView from './views/ChallengeView'

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/post/view/:id" element={<PostView />} />
                <Route exact path="/" element={<MainView />} />

                <Route exact path="/upload" element={<ChallengeView />} />

                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        </Router>
    );
}

export default App;